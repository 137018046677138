import React from "react";
import { Link } from "react-router-dom";
import Icon from "../../components/icons";
import { SettingsSupport, settingsData } from "../Settings/settingsData";
import { useSelector } from "../../store/hooks";

interface NotApprovedProps {
  t: any;
}

const NotApproved: React.FC<NotApprovedProps> = ({ t }) => {
  const description = t("not_approved.description", { returnObjects: true });
  const applications = useSelector((state) => state.applications);

  return (
    <section
      className="section"
      style={{ paddingTop: "0rem", height: "100vh" }}
    >
      <div className="not-approved">
        <div className="not-approved__icon">
          <Icon name="no_user" />
        </div>
        <div className="not-approved__content">
          <span className="not-approved__content--title">
            {t("not_approved.title")}
          </span>
          {description && (
            <div className="not-approved__content--container">
              <span className="not-approved__content--description">
                {description[0]} <br />
                {applications.invalid_reason ? (
                  <span className="not-approved__content--description">
                    <b>{t("not_approved.reason")}: </b>
                    {applications.invalid_reason}
                  </span>
                ) : (
                  description[1]
                )}
              </span>
            </div>
          )}
        </div>
        <div className="settings__container">
          <div className="settings__box">
            {settingsData.map((item: SettingsSupport, index: number) => (
              <div key={index} style={{ width: "100%" }}>
                <Link
                  to={item.href}
                  className="settings__box--item"
                  target="_blank"
                >
                  <div className="settings__box--icon">
                    <Icon name={item.icon} />
                  </div>
                  <div className="settings__box--content">
                    <span className="settings__box--title">{t(item.text)}</span>
                    <span className="settings__box--subtitle">
                      {item.value}
                    </span>
                  </div>
                </Link>
                {index !== settingsData.length - 1 && (
                  <div className="settings__box--line"></div>
                )}
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default NotApproved;
