export const dataTJ = {
  limit: {
    caption: "Лимити кредити шумо",
    info_popup: "Маблағи максималие, ки шумо метавонед аз Planet9 кредит гиред",
    prefix: "то ",
    currency: "с.",
    undefined: "Limit undefined",
  },

  create: {
    heading: "Дархост ба кредит",
    btn: {
      continue: "Давом додани дархост",
      approved: "Дархости шумо тасдиқ карда шуд",
      create: "Сохтани дархост",
    },

    steps: [
      {
        title: "Шаклро пур кунед",
        subtitle: "1-2 дақиқа",
      },
      {
        title: "Миқдори маблағ ва давраро интихоб кунед",
        subtitle: "1-2 дақиқа",
      },
      {
        title: "Натиҷаро фавран пайдо кунед",
        subtitle: "1-2 дақиқа",
      },
    ],
  },

  otp: {
    title: "Ба шумо коди тасдиқ бо SMS рақам юборид шудааст",
    description: "Лутфан ин кодро дар зерин ворид кунед",
    send_btn: "Юборид",
    resend_btn: "Кодро дубора талаб кунед",
    back_btn: "Бозгардон",
  },

  list: {
    heading: "Заявки",
    no_applications: "Шумо ҳоло ягон дархости фаъол надоред",

    status: {
      created: "Сохта шуд",
      complete: "Дар тафтиш",
      rejected: "Рад карда шуд",
      approved: "Тасдиқ карда шуд",
      issued: "Кредит дода шуд",
      reimbursed: "Кредит пардохт карда шуд",
    },
  },

  overview: {
    heading: "Дархост",
    btn_close: "Пушидан",
    currency: " сомони",
    no_amount: "Маблағ муайян карда нашудааст",
    not_defined: "Нишон дода нашудааст",
    month: " моҳ.",
    months: " моҳ.",
    hint_complete:
      "Дархости шумо дар тафтиш аст. Лутфан 3-5 дақиқа интизор шавед",
    hint_approved: "Дархости шумо тасдиқ карда шуд. Лутфан интизор шавед",

    loan_details: {
      title: "Маълумот оиди кредит",
      loan_amount: "Маблағи кредит",
      loan_amount_to_pay: "Маблағи пардохт",
      loan_duration: "Мӯҳлати кредит",
      monthly_payment: "Пардохти моҳона",
    },

    application_details: {
      title: "Маълумот оиди дархост",
      status: "Статуси дархост",
      id: "Рақами дархост",
      date_created: "Санаи сохтан",
      payment_method: "Усули қабул",
      rejection_reason: "Сабаби рад",
    },

    status: {
      created: "Дархост сохта шуд",
      complete: "Дархост дар тафтиш",
      rejected: "Дархост рад карда шуд",
      approved: "Дархост тасдиқ карда шуд",
      issued: "Кредит дода шуд",
      reimbursed: "Кредит пардохт карда шуд",
    },

    steps: {
      created: "Сохта шуд",
      complete: "Дар тафтиш",
      rejected: "Рад карда шуд",
      approved: "Тасдиқ карда шуд",
      issued: "Дода шуд",
      reimbursed: "Пардохт карда шуд",
    },
  },

  application: {
    title: "Дархост ба кредит",
    step_one: "Қадами 1 аз 3",
    step_two: "Қадами 2 аз 3",
    submit_btn: "Фиристодани дархост",
    finish_later_btn: "Баъдтар анҷом додан",
    continue_btn: "Давом додан",
    choose_label: "Интихоб кардан",

    inputs: {
      gender: "Ҷинс",
      education: "Маълумот",
      dependents: "Шумораи вобастагон",
      marital_status: "Вазъи оилавӣ",
      district: "Шаҳр",
      nationality: "Миллат",
      region: "Вилоят",
      country: "Давлат",
      loan_amount: "Миқлори кредит",
      loan_duration: "Мӯҳлат / Комиссия",
      loan_details: "Маълумот оиди дархост",
    },

    loan_details: {
      monthly_payment: {
        first: "Пардохти",
        second: "моҳона",
      },
      loan_duration: {
        first: "Мӯҳлати",
        second: "кредит",
      },
      repayment_amount: {
        first: "Миқлори",
        second: "пардохт",
      },
    },

    months: ["1 моҳ", "3 моҳ", "6 моҳ"],

    popups: {
      loan_amount:
        "Маблағи кредитро бо истифода аз слайдер интихоб кунед ё онро дастӣ ворид кунед",
      duration:
        "Мӯҳлати кредитро интихоб кунед ва комиссияи бонкро тафтиш кунед",
      loan_details: "Маълумоти кредити худро бинед",
      dependents:
        "Шахсиятҳои ки дар муассисаи истиқомати шахси дигар муайянанд. Максимум: 15",
    },
  },

  settings: {
    header: "Настройки",
    language: "Забон",
    phone: "Рақами телефон",
    email: "Почтаи электронӣ",
    website: "Веб-сайт",
    interface: "Интерфейс",
    support: "Поддержка",
    instagram: "Instagram",
  },

  not_approved: {
    title: "Доступи хатм шуд",
    description: [
      "Доступ ба платформа маҳдуд аст.",
      "Лутфан барои дарёфти иловаи маълумот бо мо тамос гиред.",
    ],
    reason: "Сабаб",
  },

  maintenance: {
    title: ["Система", "дар хизматрасонӣ"],
    description: [
      "Мо барои неғаҳдорӣҳои муваққати",
      "маъзуриатро маъзур мекунем. Баъдтар санҷед",
    ],
  },

  modals: {
    save: {
      title: "Сақи шиносида?",
      description: "Ивазҳои шумо шавандагон шуданда намешавад",
      btn_one: "Сақи нашавад",
      btn_two: "Сақи шавад",
    },

    submit: {
      title: "Сабт намудани шиноса?",
      description: "Мо шиносаи шуморо дар дармони аз 2 дақиқа баргузор мекунем",
      btn_one: "Бозгардон",
      btn_two: "Сабт намудани шиноса",
    },

    error_300: {
      title: "Хатои валидатсия",
      description: "Миқдори кредит набояд аз 300 сомони пеш амозада шавад",
      btn_one: "Бозгардон",
    },
  },
};
