export const dataRU = {
  limit: {
    caption: "Ваш лимит на кредит",
    info_popup:
      "Максимальная сумма, которую вы можете взять в кредит от Planet9",
    prefix: "до ",
    currency: "c.",
    undefined: "Лимит не указан",
  },

  create: {
    heading: "Заявка на кредит",
    btn: {
      continue: "Продолжить заявку",
      approved: "Ваша заявка одобрена",
      create: "Создать заявку",
    },

    steps: [
      {
        title: "Заполните форму",
        subtitle: "1-2 минуты",
      },
      {
        title: "Выберите сумму и период",
        subtitle: "1-2 минуты",
      },
      {
        title: "Узнайте мгновенный результат",
        subtitle: "1-2 минуты",
      },
    ],
  },

  list: {
    heading: "Заявки",
    no_applications: "У вас пока нет активных заявок",

    status: {
      created: "Создано",
      complete: "На рассмотрении",
      rejected: "Отказано",
      approved: "Одобрено",
      issued: "Кредит выдан",
      reimbursed: "Кредит погашен",
    },
  },

  otp: {
    title: "Вам отправлено SMS с кодом подтверждения",
    description: "Пожалуйста введите этот код ниже",
    send_btn: "Отправить",
    resend_btn: "Запросить код еще раз",
    back_btn: "Вернуться",
  },

  overview: {
    heading: "Заявка",
    btn_close: "Закрыть",
    currency: " сомони",
    no_amount: "Сумма не указана",
    not_defined: "Не указано",
    month: " мес.",
    months: " мес.",
    hint_complete:
      "Ваша заявка находится на рассмотрении. Пожалуйста, подождите 3-5 мин. до получения ответа.",
    hint_approved:
      "Ваша заявка одобрена. Ожидайте зачисления средств на ваш счет.",

    loan_details: {
      title: "Детали кредита",
      loan_amount: "Сумма кредита",
      loan_duration: "Срок кредита",
      loan_amount_to_pay: "Сумма к погашению",
      monthly_payment: "Ежемесячный платеж",
    },

    application_details: {
      title: "Данные заявки",
      status: "Статус заявки",
      id: "Номер заявки",
      date_created: "Дата создания",
      payment_method: "Метод получения",
      rejection_reason: "Причина отказа",
    },

    status: {
      created: "Заявка создана",
      complete: "Заявка на рассмотрении",
      rejected: "Заявка отказана",
      approved: "Заявка одобрена",
      issued: "Кредит выдан",
      reimbursed: "Кредит погашен",
    },

    steps: {
      created: "Создано",
      complete: "Подано",
      rejected: "Отказано",
      approved: "Одобрено",
      issued: "Выдан",
      reimbursed: "Погашен",
    },
  },

  application: {
    title: "Заявка на кредит",
    step_one: "Шаг 1 из 3",
    step_two: "Шаг 2 из 3",
    submit_btn: "Подать заявку",
    finish_later_btn: "Закончить позже",
    continue_btn: "Продолжить",
    choose_label: "Выбрать",
    search_placeholder: "Поиск",

    inputs: {
      gender: "Пол",
      education: "Образование",
      dependents: "Кол-во иждивенцев",
      marital_status: "Семейное положение",
      district: "Город",
      nationality: "Национальность",
      region: "Регион",
      country: "Гражданство",
      loan_amount: "Сумма кредита",
      loan_duration: "Период / Комиссия",
      loan_details: "Детали кредита",
    },

    loan_details: {
      monthly_payment: {
        first: "Ежемесячный",
        second: "платеж",
      },
      loan_duration: {
        first: "Срок",
        second: "кредита",
      },
      repayment_amount: {
        first: "Сумма",
        second: "погашения",
      },
    },
    months: ["1 мес.", "3 мес.", "6 мес."],
    popups: {
      loan_amount:
        "Выберите сумму кредита с помощью ползунка или введите её вручную",
      duration: "Выберите срок кредита и проверьте комиссию банка",
      loan_details: "Просмотрите детали вашего кредита",
      dependents:
        "Лица, которые находятся на материальном обеспечении другого лица. Максимум: 15",
    },
  },

  settings: {
    header: "Настройки",
    language: "Язык",
    phone: "Телефон",
    email: "Почта",
    website: "Веб-сайт",
    interface: "Интерфейс",
    support: "Поддержка",
    instagram: "Instagram",
  },

  not_approved: {
    title: "Ошибка доступа",
    description: [
      "Доступ к платформе ограничен.",
      "Пожалуйста, свяжитесь с нами для получения дополнительной информации.",
    ],
    reason: "Причина",
  },

  maintenance: {
    title: ["Ведутся", "технические работы"],
    description: [
      "Приносим извинения за временные неудобства.",
      "Попробуйте позже",
    ],
  },

  modals: {
    save: {
      title: "Сохранить заявку?",
      description: "Ваши изменения могут быть не сохранены",
      btn_one: "Не сохранять",
      btn_two: "Сохранить",
    },

    submit: {
      title: "Отправить заявку?",
      description: "Мы рассмотрим вашу заявку в течение 2 минут",
      btn_one: "Назад",
      btn_two: "Отправить заявку",
    },

    error_300: {
      title: "Ошибка валидации",
      description: "Сумма кредита не может быть менее 300 сомони",
      btn_one: "Назад",
    },
  },
};
