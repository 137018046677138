import { iconNames } from "../../components/icons/svg-list";

export interface SettingsSupport {
  icon: iconNames;
  text: string;
  value: string;
  href: string;
}
export const settingsData: SettingsSupport[] = [
  {
    icon: "call" as iconNames,
    text: "settings.phone",
    value: "+992 (92) 588 6565",
    href: "tel: +992925886565",
  },
  {
    icon: "email" as iconNames,
    text: "settings.email",
    value: "ops@planet9.ae",
    href: "mailto: ops@planet9.ae",
  },
  {
    icon: "globe" as iconNames,
    text: "settings.website",
    value: "https://planet9.tj",
    href: "https://planet9.tj/",
  },
  {
    icon: "instagram" as iconNames,
    text: "settings.instagram",
    value: "@planet9.app",
    href: "https://www.instagram.com/planet9.app?igsh=MzRlODBiNWFlZA==",
  },
];
