import React, { lazy, Suspense, useEffect, useRef, useState } from "react";
import { Route, Routes, useNavigate, useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "./store/hooks";
import { useTranslation } from "react-i18next";
import NotApproved from "./pages/NotApproved/NotApproved";
import OTP from "./pages/OTP/OTP";
import Maintenance from "./pages/Maintenance/Maintenance";

const ApplicationOne = lazy(() => import("./pages/Application/ApplicationOne"));
const ApplicationOverview = lazy(
  () => import("./pages/ApplicationOverview/ApplicationOverview")
);
const ApplicationTwo = lazy(() => import("./pages/Application/ApplicationTwo"));
const Main = lazy(() => import("./pages/Main/Main"));
const Modal = lazy(() => import("./components/Modal/Modal"));
const Settings = lazy(() => import("./pages/Settings/Settings"));
const OnBoarding = lazy(() => import("./pages/OnBoarding/OnBoarding"));

const App: React.FC = () => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [isModalErrorVisible, setIsModalErrorVisible] = useState(false);
  const [currentError, setCurrentError] = useState<any>(null);
  const [isFirstVisit, setIsFirstVisit] = useState(true);
  const applications = useSelector((state) => state.applications);
  const client_id = searchParams.get("client_id");
  const signature = searchParams.get("signature");
  const age = searchParams.get("age");
  const credit_history_count = searchParams.get("credit_history_count");

  const tokenRequestRef = useRef<{
    client_id: string;
    signature: string;
  } | null>(null);

  useEffect(() => {
    dispatch.applications.getIsMaintenance();
  }, []);

  useEffect(() => {
    if (client_id && signature) {
      if (
        !tokenRequestRef.current ||
        tokenRequestRef.current.client_id !== client_id ||
        tokenRequestRef.current.signature !== signature
      ) {
        tokenRequestRef.current = { client_id, signature };
        if (applications.user && applications.user.client.id !== client_id) {
          localStorage.clear();
          dispatch.applications.SET_INITIAL_STATE();
        }
        dispatch.applications.SET_URL_PARAMS({
          ...applications.url_params,
          signature,
          client_id,
        });
        dispatch.applications.getToken();
      }
    }
  }, [
    client_id,
    signature,
    applications.user,
    applications.url_params,
    dispatch.applications,
  ]);

  useEffect(() => {
    if (age && credit_history_count) {
      dispatch.applications.SET_URL_PARAMS({
        age,
        credit_history_count,
      });
    }
  }, [age, credit_history_count, dispatch.applications]);

  useEffect(() => {
    if (applications.error) {
      setCurrentError(applications.error);
      setIsModalErrorVisible(true);
      dispatch.applications.CLEAR_ERROR();
      navigate("/");
    }
  }, [applications.error, dispatch.applications, navigate]);

  useEffect(() => {
    if (applications.language) {
      i18n.changeLanguage(applications.language);
    } else {
      i18n.changeLanguage("ru");
      dispatch.applications.SET_LANGUAGE("ru");
    }
  }, [applications.language, dispatch.applications, i18n]);

  useEffect(() => {
    if (applications.user && applications.user.is_first_visit && isFirstVisit) {
      navigate("/on-boarding");
    }
    setIsFirstVisit(false);
  }, []);

  console.log(applications.maintenance);

  if (applications.maintenance === true) {
    return <Maintenance t={t} />;
  } else if (!applications.user) {
    return <NotApproved t={t} />;
  } else {
    return (
      <Suspense
        fallback={
          <div className="loader">
            <span className="loader__element"></span>
          </div>
        }
      >
        {applications.loading && (
          <div className="loader">
            <span className="loader__element"></span>
          </div>
        )}
        <Modal
          isVisible={isModalErrorVisible}
          title={currentError && currentError.title ? currentError.title : ""}
          description={
            currentError && currentError.subtitle ? currentError.subtitle : ""
          }
          btnTitle={t("modals.error_300.btn_one")}
          status="error"
          setModalVisible={setIsModalErrorVisible}
        />
        <Routes>
          <Route
            path="/"
            element={
              <Main
                t={t}
                currentLanguage={i18n.language.slice(0, 2) as "ru" | "tj"}
              />
            }
          />
          <Route path={"/settings"} element={<Settings t={t} i18={i18n} />} />
          <Route path="/on-boarding" element={<OnBoarding t={t} />} />
          <Route
            path="/application-one"
            element={
              <ApplicationOne
                t={t}
                currentLanguage={i18n.language.slice(0, 2) as "ru" | "tj"}
              />
            }
          />
          <Route path="/application-two" element={<ApplicationTwo t={t} />} />
          <Route
            path="/application-overview"
            element={<ApplicationOverview t={t} />}
          />
          <Route path="/otp-verification" element={<OTP t={t} />} />
        </Routes>
      </Suspense>
    );
  }
};

export default React.memo(App);
