import { Models, RematchDispatch, RematchRootState } from "@rematch/core";

import applications from "./applications";

export interface IRootModel extends Models<IRootModel> {
  applications: typeof applications;
}
export type TDispatch = RematchDispatch<IRootModel>;
export type TRootState = RematchRootState<IRootModel>;
export const models: IRootModel = { applications };
