import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Icon from "../../components/icons";
import ReactInputVerificationCode from "react-input-verification-code";
import { useDispatch, useSelector } from "../../store/hooks";
import classNames from "classnames";

interface OTPProps {
  t: any;
}

const OTP: React.FC<OTPProps> = ({ t }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const applications = useSelector((state) => state.applications);
  const [counter, setCounter] = useState(120);
  const [otpCode, setOTPCode] = React.useState("");

  const state = location.state as {
    application_id: number | null;
  };

  useEffect(() => {
    if (counter > 0) {
      const timer = setTimeout(() => setCounter(counter - 1), 1000);
      return () => clearTimeout(timer);
    }
  }, [counter]);

  const handleCompleteApplication = async () => {
    if (!otpCode) return;
    if (state.application_id) {
      await dispatch.applications.verifyOTP({
        application_id: state.application_id,
        otp_code: otpCode,
      });

      await dispatch.applications.completeApplication({
        id: state?.application_id,
      });

      await dispatch.applications.getApplicationByID({
        id: state?.application_id,
      });

      dispatch.applications.CLEAR_OTP_RESPONSE();

      navigate("/application-overview", {
        state: { item: applications.selectedApplication },
      });
    }
  };

  const handleSendOTP = async () => {
    if (state.application_id) {
      await dispatch.applications.sendOTP({
        application_id: state.application_id,
      });
      setCounter(120);
    }
  };

  return (
    <section className="section" style={{ height: "100vh" }}>
      <div className="otp">
        <div className="not-approved__icon">
          <Icon name="key" />
        </div>
        <div className="otp__header">
          <div className="not-approved__content">
            <span className="not-approved__content--title">
              {t("otp.title")}
            </span>
            <span className="not-approved__content--description">
              {t("otp.description")}
            </span>
          </div>
        </div>
        <ReactInputVerificationCode onChange={setOTPCode} value={otpCode} />
      </div>
      <div className="otp__buttons">
        <button className="btn" onClick={handleCompleteApplication}>
          {t("otp.send_btn")}
        </button>

        <button
          className={classNames("btn", counter > 0 && "is-disabled")}
          disabled={counter > 0}
          onClick={handleSendOTP}
        >
          {t("otp.resend_btn")} {counter > 0 ? `(${counter})` : ""}
        </button>
        <button className="btn btn--outlined" onClick={() => navigate("/")}>
          {t("otp.back_btn")}
        </button>
      </div>
    </section>
  );
};

export default OTP;
