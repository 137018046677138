import axios from "axios";
import store from "..";

let originalRequest: any;
const axiosInstance = axios.create();
axiosInstance.interceptors.response.use(
  async (response) => response,
  async (error) => {
    originalRequest = {
      ...error.config,
      _retry: originalRequest?._retry ?? false,
    };

    if (
      error.response?.status === 401 &&
      !originalRequest._retry &&
      error.response.data?.is_valid !== false
    ) {
      originalRequest._retry = true;
      localStorage.clear();
      try {
        const { client_id, signature } =
          store.getState().applications.url_params || {};
        const response = await axiosInstance
          .post(process.env.REACT_APP_API_URL + "auth", {
            signature: signature ? signature.replace(/\s/g, "+") : "", // Get signature from state or use an empty string if it doesn't exist
            client_id: client_id || "", // Get client_id from state or use an empty string if it doesn't exist
          })
          .then((res) => res.data);

        const newToken = response.token;
        originalRequest.headers.Authorization = `Bearer ${newToken}`;
        store.dispatch.applications.GET_TOKEN_SUCCESS(response);
        return axiosInstance(originalRequest);
      } catch (refreshError: any) {
        localStorage.clear();
        store.dispatch.applications.SET_INITIAL_STATE();
        throw refreshError;
      }
    } else {
      store.dispatch.applications.SET_INVALID_REASON(
        error.response.data.invalid_reason
      );
      return Promise.reject(error);
    }
  }
);
export default axiosInstance;
