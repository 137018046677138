import { TRootState, models } from "./models";

import { init } from "@rematch/core";
import { localstorageState } from "./utils";

const store = init({
  models,
  redux: {
    initialState: localstorageState.get(),
  },
});

store.subscribe(() => {
  const { applications } = store.getState();

  let state: Partial<TRootState> = {};
  state.applications = applications;

  return localstorageState.set(state);
});

export default store;
