import { User, initialStateInterface } from "../../interfaces/interfaces";

import { IRootModel } from ".";
import axiosInstance from "../utils/instance";
import { createModel } from "@rematch/core";
import { errorHandling } from "../../utils/utils";

const initialState: initialStateInterface = {
  user: null,
  allApplications: [],
  selectedApplication: null,
  loading: false,
  error: null,
  success: null,
  otp_response: null,
  districts: [],
  language: "ru",
  url_params: null,
  invalid_reason: null,
  maintenance: null,
};

const applications = createModel<IRootModel>()({
  state: initialState,
  reducers: {
    GET_TOKEN_PENDING(state) {
      return { ...state, loading: true };
    },
    GET_TOKEN_SUCCESS(state, user) {
      return { ...state, loading: false, error: null, user };
    },
    GET_TOKEN_FAILURE(state, error) {
      return { ...state, loading: false, error };
    },
    GET_ALL_APPLICATIONS_PENDING(state) {
      return { ...state, loading: true };
    },
    GET_ALL_APPLICATIONS_SUCCESS(state, allApplications) {
      return { ...state, loading: false, error: null, allApplications };
    },
    GET_ALL_APPLICATIONS_FAILURE(state, error) {
      return { ...state, loading: false, error };
    },
    GET_APPLICATION_BY_ID_PENDING(state) {
      return { ...state, loading: true };
    },
    GET_APPLICATION_BY_ID_SUCCESS(state, selectedApplication) {
      return { ...state, loading: false, error: null, selectedApplication };
    },
    GET_APPLICATION_BY_ID_ERROR(state, error) {
      return { ...state, loading: false, error };
    },
    CREATE_APPLICATION_PENDING(state) {
      return { ...state, loading: true };
    },
    CREATE_APPLICATION_SUCCESS(state, success) {
      return { ...state, loading: false, error: null, success };
    },
    CREATE_APPLICATION_FAILURE(state, error) {
      return { ...state, loading: false, error };
    },
    UPDATE_APPLICATION_PENDING(state) {
      return { ...state, loading: true };
    },
    UPDATE_APPLICATION_SUCCESS(state, success) {
      return { ...state, loading: false, error: null, success };
    },
    UPDATE_APPLICATION_FAILURE(state, error) {
      return { ...state, loading: false, error };
    },
    COMPLETE_APPLICATION_PENDING(state) {
      return { ...state, loading: true };
    },
    COMPLETE_APPLICATION_SUCCESS(state, success) {
      return { ...state, loading: false, error: null, success };
    },
    COMPLETE_APPLICATION_FAILURE(state, error) {
      return { ...state, loading: false, error };
    },
    DELETE_APPLICATION_PENDING(state) {
      return { ...state, loading: true };
    },
    DELETE_APPLICATION_SUCCESS(state, success) {
      return { ...state, loading: false, error: null, success };
    },
    DELETE_APPLICATION_FAILURE(state, error) {
      return { ...state, loading: false, error };
    },
    GET_ALL_DISTRICTS_PENDING(state) {
      return { ...state, loading: true };
    },
    GET_ALL_DISTRICTS_SUCCESS(state, districts) {
      return { ...state, loading: false, error: null, districts };
    },
    GET_ALL_DISTRICTS_FAILURE(state, error) {
      return { ...state, loading: false, error };
    },
    SET_INITIAL_STATE(state) {
      return { ...state, ...initialState };
    },
    CLEAR_ERROR(state) {
      return { ...state, loading: false, error: null };
    },
    SET_LANGUAGE(state, language) {
      return { ...state, loading: false, language };
    },
    SET_URL_PARAMS(state, url_params) {
      return { ...state, loading: false, url_params };
    },
    SET_INVALID_REASON(state, invalid_reason) {
      return { ...state, loading: false, invalid_reason };
    },
    SEND_OTP_PENDING(state) {
      return { ...state, loading: true };
    },
    SEND_OTP_SUCCESS(state, otp_response) {
      return { ...state, loading: false, error: null, otp_response };
    },
    SEND_OTP_FAILURE(state, error) {
      return { ...state, loading: false, error };
    },
    VERIFY_OTP_PENDING(state) {
      return { ...state, loading: true };
    },
    VERIFY_OTP_SUCCESS(state, otp_response) {
      return { ...state, loading: false, error: null, otp_response };
    },
    VERIFY_OTP_FAILURE(state, error) {
      return { ...state, loading: false, error };
    },
    CLEAR_OTP_RESPONSE(state) {
      return { ...state, otp_response: null };
    },
    CHECK_MAINTANCE_PENDING(state) {
      return { ...state, loading: true };
    },
    CHECK_MAINTANCE_SUCCESS(state, maintenance) {
      return { ...state, loading: false, error: null, maintenance };
    },
    CHECK_MAINTANCE_FAILURE(state, error) {
      return { ...state, loading: false, error };
    },
  },
  effects: (dispatch) => ({
    async getToken(_: void, rootState: any) {
      const { client_id, signature } = rootState.applications.url_params;
      const signatureWithoutSpaces = signature.replace(/\s/g, "+"); // Remove spaces from the signature
      try {
        dispatch.applications.GET_TOKEN_PENDING();
        let user = await axiosInstance
          .post(
            process.env.REACT_APP_API_URL + "auth",
            {
              signature: signatureWithoutSpaces,
              client_id,
            },
            {}
          )
          .then((res) => res.data);
        dispatch.applications.GET_TOKEN_SUCCESS(user);
      } catch (e: any) {
        const language = rootState.applications.language;
        if (e.response.data?.is_valid === false) {
          dispatch.applications.SET_INVALID_REASON(
            e.response.data.invalid_reason
          );
        } else {
          dispatch.applications.GET_TOKEN_FAILURE(
            errorHandling(e.response.data?.error, language)
          );
        }
      }
    },

    async createApplication(_: void, rootState) {
      try {
        dispatch.applications.CREATE_APPLICATION_PENDING();
        const { token } = rootState.applications.user as User;

        let response = await axiosInstance
          .post(
            process.env.REACT_APP_API_URL + "loans",
            {},
            {
              headers: {
                "Rnpl-Partner-Id": "dcity",
                Authorization: `Bearer ${token}`,
              },
            }
          )
          .then((res) => res.data);
        dispatch.applications.CREATE_APPLICATION_SUCCESS(response);
      } catch (e: any) {
        const language = rootState.applications.language;
        dispatch.applications.CREATE_APPLICATION_FAILURE(
          errorHandling(e.response.data?.error, language)
        );
      }
    },

    async updateApplication(payload, rootState: any) {
      const { form, id, client_id } = payload;
      const { success } = rootState.applications;
      const applicationID = id === 0 ? success.id : id;
      const applicationClientID =
        client_id === 0 ? success.client_id : client_id;
      try {
        dispatch.applications.UPDATE_APPLICATION_PENDING();
        const { token } = rootState.applications.user;
        const finalForm = { ...form, client_id: applicationClientID };
        let response = await axiosInstance
          .put(
            process.env.REACT_APP_API_URL + `loans/${applicationID}`,
            finalForm,
            {
              headers: {
                "Rnpl-Partner-Id": "dcity",
                Authorization: `Bearer ${token}`,
              },
            }
          )
          .then((res) => res.data);
        dispatch.applications.UPDATE_APPLICATION_SUCCESS(response);
      } catch (e: any) {
        const language = rootState.applications.language;
        dispatch.applications.UPDATE_APPLICATION_FAILURE(
          errorHandling(e.response.data?.error, language)
        );
      }
    },

    async getAllApplications(_: void, rootState) {
      try {
        dispatch.applications.GET_ALL_APPLICATIONS_PENDING();
        const { token } = rootState.applications.user as User;
        let response = await axiosInstance
          .get(process.env.REACT_APP_API_URL + "loans", {
            headers: {
              "Rnpl-Partner-Id": "dcity",
              Authorization: `Bearer ${token}`,
            },
            params: {
              page: 1,
              per_page: 10,
            },
          })
          .then((res) => res.data);
        dispatch.applications.GET_ALL_APPLICATIONS_SUCCESS(
          response.loan_applications
        );
      } catch (e: any) {
        const language = rootState.applications.language;
        dispatch.applications.GET_ALL_APPLICATIONS_FAILURE(
          errorHandling(e.response.data?.error, language)
        );
      }
    },

    async getApplicationByID(payload, rootState: any) {
      const { id } = payload;
      try {
        dispatch.applications.GET_APPLICATION_BY_ID_PENDING();
        const { token } = rootState.applications.user as User;
        let response = await axiosInstance
          .get(process.env.REACT_APP_API_URL + `loans/${id}`, {
            headers: {
              "Rnpl-Partner-Id": "dcity",
              Authorization: `Bearer ${token}`,
            },
          })
          .then((res) => res.data);
        dispatch.applications.GET_APPLICATION_BY_ID_SUCCESS(response);
      } catch (e: any) {
        const language = rootState.applications.language;
        dispatch.applications.GET_APPLICATION_BY_ID_ERROR(
          errorHandling(e.response.data?.error, language)
        );
      }
    },

    async getAllDistricts(_: void, rootState) {
      try {
        dispatch.applications.GET_ALL_DISTRICTS_PENDING();
        const { token } = rootState.applications.user as User;
        let response = await axiosInstance
          .get(process.env.REACT_APP_API_URL + "districts", {
            headers: {
              "Rnpl-Partner-Id": "dcity",
              Authorization: `Bearer ${token}`,
            },
          })
          .then((res) => res.data);
        dispatch.applications.GET_ALL_DISTRICTS_SUCCESS(response);
      } catch (e: any) {
        const language = rootState.applications.language;
        dispatch.applications.GET_ALL_DISTRICTS_FAILURE(
          errorHandling(e.response.data?.error, language)
        );
      }
    },

    async deleteApplication(payload, rootState: any) {
      const { id } = payload;
      try {
        dispatch.applications.DELETE_APPLICATION_PENDING();
        const { token } = rootState.applications.user as User;
        let response = await axiosInstance
          .delete(process.env.REACT_APP_API_URL + `loans/${id}`, {
            headers: {
              "Rnpl-Partner-Id": "dcity",
              Authorization: `Bearer ${token}`,
            },
          })
          .then((res) => res.data);
        dispatch.applications.DELETE_APPLICATION_SUCCESS(response);
      } catch (e: any) {
        const language = rootState.applications.language;
        dispatch.applications.DELETE_APPLICATION_FAILURE(
          errorHandling(e.response.data?.error, language)
        );
      }
    },

    async completeApplication(payload, rootState: any) {
      const { id } = payload;
      try {
        dispatch.applications.COMPLETE_APPLICATION_PENDING();
        const { token } = rootState.applications.user as User;
        let response = await axiosInstance
          .post(
            process.env.REACT_APP_API_URL + `loans/${id}/complete`,
            {},
            {
              headers: {
                "Rnpl-Partner-Id": "dcity",
                Authorization: `Bearer ${token}`,
              },
            }
          )
          .then((res) => res.data);
        dispatch.applications.COMPLETE_APPLICATION_SUCCESS(response);
      } catch (e: any) {
        const language = rootState.applications.language;
        dispatch.applications.COMPLETE_APPLICATION_FAILURE(
          errorHandling(e.response.data?.error, language)
        );
      }
    },

    async sendOTP(payload, rootState: any) {
      const { application_id } = payload;
      try {
        dispatch.applications.SEND_OTP_PENDING();
        const { token } = rootState.applications.user as User;
        let response = await axiosInstance
          .post(
            process.env.REACT_APP_API_URL + `loans/otp/send`,
            {
              loan_application_id: application_id,
            },
            {
              headers: {
                "Rnpl-Partner-Id": "dcity",
                Authorization: `Bearer ${token}`,
              },
            }
          )
          .then((res) => res);
        dispatch.applications.SEND_OTP_SUCCESS(response.data);
      } catch (e: any) {
        const language = rootState.applications.language;
        if (
          e.response.data?.error === "Errors.LoanApplication.HasUnexpiredOTP"
        ) {
          dispatch.applications.SEND_OTP_SUCCESS(e.response.data);
        } else {
          dispatch.applications.SEND_OTP_FAILURE(
            errorHandling(e.response.data?.error, language)
          );
        }
      }
    },

    async verifyOTP(payload, rootState: any) {
      const { application_id, otp_code } = payload;
      try {
        dispatch.applications.VERIFY_OTP_PENDING();
        const { token } = rootState.applications.user as User;
        let response = await axiosInstance
          .post(
            process.env.REACT_APP_API_URL + `loans/otp/verify`,
            {
              loan_application_id: application_id,
              otp: otp_code,
            },
            {
              headers: {
                "Rnpl-Partner-Id": "dcity",
                Authorization: `Bearer ${token}`,
              },
            }
          )
          .then((res) => res);
        dispatch.applications.VERIFY_OTP_SUCCESS(response);
      } catch (e: any) {
        const language = rootState.applications.language;
        dispatch.applications.VERIFY_OTP_FAILURE(
          errorHandling(e?.error, language)
        );
      }
    },

    async getIsMaintenance(_: void, rootState) {
      try {
        dispatch.applications.CHECK_MAINTANCE_PENDING();
        let response = await axiosInstance
          .get(process.env.REACT_APP_API_URL + "maintenance", {
            headers: {
              "Rnpl-Partner-Id": "dcity",
            },
          })
          .then((res) => res.data);
        dispatch.applications.CHECK_MAINTANCE_SUCCESS(
          response.is_under_maintenance
        );
      } catch (e: any) {
        const language = rootState.applications.language;
        dispatch.applications.CHECK_MAINTANCE_FAILURE(
          errorHandling(e.response.data?.error, language)
        );
      }
    },
  }),
});

export default applications;
