import {
  DropdownType,
  FormValues,
  LoanStatusInterface,
} from "../interfaces/interfaces";

import { iconNames } from "../components/icons/svg-list";

export const handleStatus = (status: string | undefined) => {
  switch (status) {
    case "created":
      return {
        icon: "application" as iconNames,
        color: "yellow",
        text: "Created",
        text2: "Application's approved",
      };
    case "complete":
      return {
        icon: "clock" as iconNames,
        color: "green",
        text: "Under consideration",
        text2: "Application under consideration",
      };
    case "rejected":
      return {
        icon: "closeCircle" as iconNames,
        color: "red",
        text: "Rejected",
        text2: "Application declined",
      };
    case "approved":
      return {
        icon: "mark" as iconNames,
        color: "green",
        text: "Approved",
        text2: "Application approved",
      };
    case "issued":
      return {
        icon: "walletAdd" as iconNames,
        color: "green",
        text: "Credit issued",
        text2: "Credit issued",
      };
    case "reimbursed":
      return {
        icon: "walletAdd" as iconNames,
        color: "green",
        text: "Credit repaid",
        text2: "Credit repaid",
      };
    default:
      return {
        icon: "clock" as iconNames,
        color: "yellow",
        text: "Status in process",
        text2: "Status in process",
      };
  }
};

export const loanStatus: LoanStatusInterface = {
  created: {
    id: 0,
    icon: "application",
    text: "Application created",
    color: "#717178",
    background: "#F0F0F0",
  },
  complete: {
    id: 1,
    icon: "clock",
    text: "Under consideration",
    color: "#717178",
    background: "#F0F0F0",
  },
  rejected: {
    id: 2,
    icon: "rejected",
    text: "Rejected",
    color: "#FF5454",
    background: "#FFE1E1",
  },
  approved: {
    id: 3,
    icon: "mark",
    text: "Approved",
    color: "#2F8F17",
    background: "#DDF1D6",
  },
  issued: {
    id: 4,
    icon: "walletAdd",
    text: "Credit issued",
    color: "#2F8F17",
    background: "#DDF1D6",
  },
  reimbursed: {
    id: 5,
    icon: "walletDone",
    text: "Credit repaid",
    color: "#2F8F17",
    background: "#DDF1D6",
  },
};

export const getNonEmptyValues = (
  values: FormValues,
  selectedDropdownValues: {
    gender: string;
    education: string;
    marital_status: string;
    district: string;
    nationality: string;
    region: string;
  }
) => {
  const nonEmptyValues: FormValues = {} as FormValues;
  for (const [key, value] of Object.entries(values)) {
    if (value !== "" && key !== "dependents") {
      nonEmptyValues[key as keyof FormValues] = selectedDropdownValues[
        key as keyof typeof selectedDropdownValues
      ] as string;
    } else if (value !== "") {
      nonEmptyValues[key as keyof FormValues] = value as string;
    }
  }
  return convertObject(nonEmptyValues);
};

const convertObject = (values: FormValues) => {
  const result = {
    marital_status:
      values && values.marital_status ? values.marital_status : null,
    gender: values && values.gender ? values.gender : null,
    dependents: values && values.dependents ? values.dependents : 0,
    district: values && values.district ? values.district : null,
    education: values && values.education ? values.education : null,
    partner_specific: {
      region: values && values.region ? values.region : null,
      nationality: values && values.nationality ? values.nationality : null,
    },
  };
  return result;
};

export const getRuValue = (
  valueDb: string | null,
  dropdownValues: DropdownType[],
  language: "ru" | "tj"
) => {
  if (valueDb === null) {
    return "";
  }
  const dropdownItem = dropdownValues.find((item) => item.value_db === valueDb);
  return dropdownItem ? dropdownItem.values[language] : "";
};

export const getDbValue = (
  value: string,
  dropdownValues: DropdownType[],
  language: "ru" | "tj"
): string => {
  const dropdownItem = dropdownValues.find(
    (item) => item.values[language] === value
  );
  return dropdownItem ? dropdownItem.value_db : "";
};

export const formatDate = (date: Date) => {
  const options: Intl.DateTimeFormatOptions = {
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
  };

  return new Date(date).toLocaleDateString("ru-RU", options);
};

const errorTranslator = {
  "Errors.Common.Unauthorized": {
    en: {
      title: "Authorization Error",
      subtitle: "This user is not authorized",
    },
    ru: {
      title: "Ошибка авторизации",
      subtitle: "Пользователь не авторизован",
    },
    tj: {
      title: "Хатоли ивазати корбар",
      subtitle: "Ин корбар авторизатсия шуда нашудааст",
    },
  },

  "Errors.Clients.ClientNotFound": {
    en: {
      title: "Error",
      subtitle: "Client not found",
    },
    ru: {
      title: "Ошибка",
      subtitle: "Клиент не найден",
    },
    tj: {
      title: "Хато",
      subtitle: "Корбар ёфт нашуд",
    },
  },

  "Errors.Common.ValidationFailed": {
    en: {
      title: "Validation Error",
      subtitle: "Please check your data or contact support",
    },
    ru: {
      title: "Ошибка валидации",
      subtitle: "Пожалуйста, проверьте ваши данные или свяжитесь с поддержкой",
    },
    tj: {
      title: "Хатоли валидатсия",
      subtitle:
        "Лутфан, маълумотҳои худро тафтиш кунед ё бо таъмингардон тамос кунед",
    },
  },

  "Errors.Common.InternalDatabaseError": {
    en: {
      title: "Database Error",
      subtitle: "Internal database error occurred",
    },
    ru: {
      title: "Ошибка базы данных",
      subtitle: "Произошла внутренняя ошибка базы данных",
    },
    tj: {
      title: "Хатоли базаи додҳилӣ",
      subtitle: "Хатоли додҳилӣи додҳилӣ рӯй дода шудааст",
    },
  },

  "Errors.LoanApplication.NotFound": {
    en: { title: "Error", subtitle: "Loan application not found" },
    ru: {
      title: "Ошибка",
      subtitle: "Заявка на кредит не найдена",
    },
    tj: {
      title: "Хато",
      subtitle: "Заявка барои кредит ёфт нашуд",
    },
  },

  "Errors.Clients.ClientNotPreApproved": {
    en: {
      title: "Error",
      subtitle:
        "At the moment we cannot accept your application. Please wait while we process your data",
    },
    ru: {
      title: "Ошибка доступа",
      subtitle:
        "В данный момент мы не можем принять вашу заявку. Пожалуйста подождите пока мы обрабатываем ваши данные",
    },
    tj: {
      title: "Хато",
      subtitle:
        "Дар ҳоли ҳозир мо наметавонем дархости шуморо қабул кунем. Лутфан, интизор шавед, то мо маълумотҳои шуморо пешкаш кунем",
    },
  },
  "Errors.LoanApplication.InvalidStatus": {
    en: {
      title: "Status Error",
      subtitle: "Invalid status for the loan application",
    },
    ru: {
      title: "Ошибка статуса",
      subtitle: "Недопустимый статус для заявки на кредит",
    },
    tj: {
      title: "Хатои ҳолат",
      subtitle: "Ҳолати нодуруст барои заявкаи кредит",
    },
  },

  "Errors.LoanApplication.ClientNotOwner": {
    en: {
      title: "Error",
      subtitle: "User is not the owner of the loan application",
    },
    ru: {
      title: "Ошибка",
      subtitle: "Пользователь не является владельцем заявки на кредит",
    },
    tj: {
      title: "Хато",
      subtitle: "Корбар вазифаи воидкунандаи заявкаи кредит нест",
    },
  },

  "Errors.LoanApplication.HasIncompleteApp": {
    en: {
      title: "Error",
      subtitle: "User already has an incomplete loan application",
    },
    ru: {
      title: "Ошибка",
      subtitle: "У пользователя уже есть неполная заявка на кредит",
    },
    tj: {
      title: "Хато",
      subtitle: "Корбар аллакай дар як заявкаи кредити нопурраста дорад",
    },
  },

  "Errors.LoanApplication.HasUnpaidLoan": {
    en: {
      title: "Error",
      subtitle: "User has an unpaid loan",
    },
    ru: {
      title: "Ошибка",
      subtitle: "У пользователя есть непогашенный кредит",
    },
    tj: {
      title: "Хато",
      subtitle: "Корбар кредити ногузашта дорад",
    },
  },

  "Errors.LoanApplication.HasUnexpiredOTP": {
    en: {
      title: "Error",
      subtitle: "User has an unexpired code",
    },
    ru: {
      title: "Ошибка",
      subtitle: "У Вас уже есть непросроченный код",
    },
    tj: {
      title: "Хато",
      subtitle: "Корбар код нагузашта дорад",
    },
  },

  "Errors.LoanApplication.InvalidOtp": {
    en: {
      title: "Error",
      subtitle: "Invalid code",
    },
    ru: {
      title: "Ошибка",
      subtitle: "Неверный код",
    },
    tj: {
      title: "Хато",
      subtitle: "Код нодуруст аст",
    },
  },

  "Errors.LoanApplication.AlreadyVerified": {
    en: {
      title: "Error",
      subtitle: "You already verified",
    },
    ru: {
      title: "Ошибка",
      subtitle: "Вы уже верифицированы",
    },
    tj: {
      title: "Хато",
      subtitle: "Корбар аллакай тасдиқ карда шудааст",
    },
  },

  "Errors.LoanApplication.OtpMaxAttemptsExceeded": {
    en: {
      title: "Error",
      subtitle: "Maximum attempts exceeded",
    },
    ru: {
      title: "Ошибка",
      subtitle: "Превышено максимальное количество попыток",
    },
    tj: {
      title: "Хато",
      subtitle: "Максималӣ ба хатоҳо гирифта шудааст",
    },
  },

  "Errors.LoanApplication.ShouldBeVerified": {
    en: {
      title: "Error",
      subtitle: "You should be verified",
    },
    ru: {
      title: "Ошибка",
      subtitle: "Вы должны быть верифицированы",
    },
    tj: {
      title: "Хато",
      subtitle: "Шумо бояд тасдиқ карда шавед",
    },
  },

  "Errors.Common.NetworkError": {
    en: {
      title: "Error",
      subtitle: "Network error",
    },
    ru: {
      title: "Ошибка",
      subtitle: "Ошибка сети",
    },
    tj: {
      title: "Хато",
      subtitle: "Хатои шабака",
    },
  },
  default: {
    en: { title: "Error", subtitle: "Server-side issues" },
    ru: { title: "Ошибка", subtitle: "Проблемы с сервером" },
    tj: { title: "Хато", subtitle: "Мушкилотҳои тарафи сервер" },
  },
};

const reasonTranslator = {
  "Reason.Rejection.BadCreditHistory": {
    en: "Bad credit history",
    ru: "Плохая кредитная история",
    tj: "Таърихи кредити навзор",
  },

  "Reason.Rejection.BadScoring": {
    en: "Scoring rejection",
    ru: "Отказ в скоринге",
    tj: "Бозгардонии анбор",
  },

  "Reason.Rejection.ComplianceFailed": {
    en: "Client does not comply with the bank's internal rules",
    ru: "Клиент не соответствует внутренним правилам банка",
    tj: "Мушкилоти таътили корбар бо қоидалҳои дохили банк",
  },

  "Reason.Rejection.ClientNotPreApproved": {
    en: "Client not pre-approved",
    ru: "Клиент предварительно неодобрен",
    tj: "Клиент предварительно одобрен нашудааст",
  },

  default: {
    en: "Technical error",
    ru: "Техническая ошибка",
    tj: "Хатои техникӣ",
  },
};

export const errorHandling = (error: string, language: "ru" | "tj" | "en") => {
  const defaultError = errorTranslator.default[language];

  if (error) {
    const errorTypeMatch = error.match(/Errors\.[\w\.]+/);
    const errorType = errorTypeMatch ? errorTypeMatch[0] : null;
    const translatedError =
      errorType &&
      errorTranslator[errorType as keyof typeof errorTranslator] &&
      errorTranslator[errorType as keyof typeof errorTranslator][language]
        ? errorTranslator[errorType as keyof typeof errorTranslator][language]
        : defaultError;

    return translatedError;
  } else {
    return defaultError;
  }
};

export const reasonHandling = (reason: string | null | undefined) => {
  if (reason) {
    return (reasonTranslator as any)[reason] || reasonTranslator.default;
  } else {
    return reasonTranslator.default;
  }
};

export const handleMonthChange = (
  loanAmount: string,
  month: { numOfMonth: 1 | 3 | 6; percent: "4%" | "8%" | "12%" }
) => {
  if (loanAmount === "") {
    return 0;
  } else {
    return (
      (parseInt(loanAmount) * (parseInt(month.percent) / 100) +
        parseInt(loanAmount)) /
      month.numOfMonth
    ).toFixed(2);
  }
};

export const handleTotalAmount = (
  loanAmount: string,
  month: { numOfMonth: 1 | 3 | 6; percent: "4%" | "8%" | "12%" }
) => {
  return (
    (handleMonthChange(loanAmount, month) as number) * month.numOfMonth
  ).toFixed(2);
};
