import React from "react";
import svgList, { iconNames } from "./svg-list";

const Icon = ({ name }: { name: iconNames }) => {
  return (
    <svg
      viewBox="0 0 24 24"
      style={{ width: "100%", height: "100%" }}
      fill="currentColor"
      className="svg"
    >
      {svgList[name]}
    </svg>
  );
};

export default Icon;
